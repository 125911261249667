interface Props {
  complexType: 0 | 1;
}

export function getComplexCategory({
  complexType,
}: Props) {
  switch (complexType) {
    case 0:
      return 'apt';

    case 1:
      return 'officetel';

    default:
      return '';
  }
}
