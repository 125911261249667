import styled from 'styled-components';

export const Header = styled.header`
  display: grid;
  grid-template-columns: minmax(0, 1fr) 24px;
  grid-column-gap: 16px;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray[200]};

  > div {
    display: flex;
    align-items: center;
    
    > h1 {
      flex: none;
      color: ${({ theme }) => theme.color.gray[900]};
      ${({ theme }) => theme.font.body2};
    }
  }

  > button {
    width: 24px;
    height: 24px;
    margin-left: auto;
    padding: 0;
    border: 0;
    background-color: transparent;
  }
`;
