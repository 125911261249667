import { addSeconds } from 'date-fns';

export interface LimitTime {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

type TCallback = (second: LimitTime, isEnd: boolean) => void;

class CountDown {
  private _second: number;
  private _interval: ReturnType<typeof setInterval> | undefined;

  constructor(second: number) {
    this._second = second;
  }

  start(callback: TCallback) {
    this.stop();

    const now = new Date();
    let endTime = addSeconds(now, this._second).getTime();

    let isFirstRound = true;
    let fixTime = 0;

    this._interval = setInterval(() => {
      const startTime = new Date().getTime();

      if (isFirstRound) {
        fixTime = startTime - now.getTime();
        endTime += fixTime;
      }

      const distance = endTime - startTime;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        this.stop();
        callback({ days: 0, hours: 0, minutes: 0, seconds: 0 }, true);

      } else {
        callback({
          days: Math.max(days, 0),
          hours: Math.max(hours, 0),
          minutes: Math.max(minutes, 0),
          seconds: Math.max(seconds, 0),
        }, false);
      }


      isFirstRound = false;
    }, 200);
  }

  stop() {
    if (this._interval) {
      clearInterval(this._interval);
      this._interval = undefined;
    }
  }

  /**
   * 한자리 수일경우 앞에 0 을 붙여준다.
   * @param time
   */
  formatZero(time: number = 0) {
    if (time < 10) return `0${time < 0 ? 0 : time}`;
    if (time <= 0) return '00';
    return time;
  }
}

export default CountDown;
