import React from 'react';

import useAdInquiry from './useAdInquiry';

import { Select, TextInput, NumberInput, LineBtn, SolidBtn } from '~/atoms2';
import { Popup } from '~/components/modal2';
import { PopupHeader, PopupContent, PopupBtnContainer } from '~/components/modal2/modules';

import Term from './term';

import { Content, Desc } from './styled';

interface Props {
  closePopup: () => void;
}

export default function AdInquiryModal({
  closePopup,
}: Props) {
  const {
    isSending,
    state,
    changeState,
    changePhone, blurPhone, focusPhone,
    changeAgree,
    submit,
  } = useAdInquiry(closePopup);

  return (
    <Popup
      popupWidth={400}
      closePopup={closePopup}
    >
      <PopupHeader
        title="배너 광고 문의"
        closePopup={closePopup}
      />

      <PopupContent>
        <Content>
          <h1>광고유형</h1>
          <Select
            name="adType"
            value={state.adType}
            onChange={changeState}
          >
            <option value="">선택해주세요</option>
            <option value="배너광고">배너광고</option>
            <option value="사업지광고">사업지광고</option>
            <option value="기타">기타</option>
          </Select>
        </Content>

        <Content>
          <h1>업체명</h1>
          <TextInput
            name="companyName"
            value={state.companyName}
            placeholder="(주) 다방건설"
            onChange={changeState}
          />
        </Content>

        <Content>
          <h1>담당자명</h1>
          <TextInput
            name="name"
            value={state.name}
            placeholder="김다방"
            onChange={changeState}
          />
        </Content>

        <Content>
          <h1>연락처</h1>
          <NumberInput
            max={11}
            value={state.tel}
            placeholder="- 없이 숫자만 입력"
            onChange={changePhone}
            onFocus={focusPhone}
            onBlur={blurPhone}
          />
        </Content>

        <Content>
          <h1>이메일</h1>
          <TextInput
            name="email"
            value={state.email}
            placeholder="dabang@station3.co.kr"
            onChange={changeState}
          />
        </Content>

        <Content>
          <h1>광고소재</h1>
          <TextInput
            name="adCreative"
            value={state.adCreative}
            placeholder="아파트, 가전 등"
            onChange={changeState}
          />
        </Content>

        <Content>
          <h1>광고희망시기</h1>
          <TextInput
            name="adHopeDate"
            value={state.adHopeDate}
            placeholder="0000년 00월"
            onChange={changeState}
          />
        </Content>

        <Term
          isAgree={state.isAgree}
          changeAgree={changeAgree}
        />

        <Desc>담당자가 확인 후 빠른 시간 내에 회신 전화를 드립니다.</Desc>
      </PopupContent>

      <PopupBtnContainer>
        <LineBtn
          contentWidth="120px"
          btnColor="gray"
          btnSize="xl"
          onClick={closePopup}
        >
          취소
        </LineBtn>

        <SolidBtn
          contentWidth="120px"
          btnColor="blue"
          btnSize="xl"
          onClick={submit}
          disabled={isSending}
        >
          제출하기
        </SolidBtn>
      </PopupBtnContainer>
    </Popup>
  );
}
