import styled from 'styled-components';

export const BtnWrap = styled.div<{
  $btnCnt: number;
}>`
  display: grid;
  grid-template-columns: ${({ $btnCnt }) => $btnCnt === 1 ? 'minmax(0, 1fr)' : 'minmax(0, 1fr) 1px minmax(0, 1fr)'};
  border-top: 1px solid ${({ theme }) => theme.color.gray[200]};

  > button {
    width: 100%;
    height: 56px;
    padding: 0;

    ${({ theme }) => theme.font.body3};
    font-weight: 700;
    border: 0;
    background-color: transparent;
  }
`;

export const Divider = styled.hr`
  width: 1px;
  height: 100%;
  margin: 0;
  border: 0;
  background-color: ${({ theme }) => theme.color.gray[200]};
`;
