import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import type { RootState, Dispatch } from '~/store';

import useApiCall from '~/hooks/useApiCall';
import { printError } from '~/utils/fetch';

import { toast } from '~/components/toast';

interface Props {
  callback?: () => void;
}

function useCheckPhone({
  callback,
}: Props) {
  const [isSending, setSending] = useState(false);
  const [isOpenSafeAuth, setOpenSafeAuth] = useState(false);

  const { post } = useApiCall();

  const { phone } = useSelector(({ user }: RootState) => user);
  const dispatch = useDispatch<Dispatch>();

  async function keepPhoneNumber() {
    if (isSending) return;

    try {
      const res = await post('/api/3/user/confirm-phone-number');
      dispatch.user.update({
        ...res,
        isCertRenewalPopup: 'close',
      });

      callback?.();

    } catch (err) {
      printError(err);

    } finally {
      setSending(false);
    }
  }

  async function editUserInfo({ name, phone }: PopupSafeAuth.SafeAuthCallbackArgs) {
    if (isSending) return;

    setSending(true);

    try {
      const res = await post('/api/3/user/safe-auth/edit', { name, phone });
      closeSafeAuth();
      dispatch.user.update({
        ...res,
        isCertRenewalPopup: 'close',
      });
      toast.success('수정 되었습니다.');

      callback?.();

    } catch (err) {
      printError(err);

    } finally {
      setSending(false);
    }
  }

  function openSafeAuth() {
    setOpenSafeAuth(true);
  }

  function closeSafeAuth() {
    setOpenSafeAuth(false);
  }

  return {
    isSending,
    isOpenSafeAuth,
    phone,
    keepPhoneNumber,
    openSafeAuth,
    closeSafeAuth,
    editUserInfo,
  };
}

export default useCheckPhone;
