import styled from 'styled-components';

export const BtnWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  padding: 0 20px 24px;
  text-align: center;
  position: relative;
  
  &:before {
    content: '';
    height: 24px;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 47%, rgba(255,255,255,1) 90%);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
  }
`;
