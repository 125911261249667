import { useState, useCallback, ChangeEvent, FormEvent, MouseEvent } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import useApiCall from '~/hooks/useApiCall';
import getErrorMessage from '~/utils/get-error-message';
import { getRoomDetailLink } from '~/utils/map/link';
import { toast } from '~/components/toast';

function useSearch() {
  const [keyword, setKeyword] = useState('');

  const location = useLocation();
  const history = useHistory();

  const { get } = useApiCall();

  function onChange({ currentTarget: { value } }: ChangeEvent<HTMLInputElement>) {
    if (!/(^[0-9]+$)|(^$)/.test(value)) return;

    setKeyword(value);
  }

  async function onSubmit(e: FormEvent) {
    e.preventDefault();

    if (keyword === '') return toast.error('매물번호를 입력해주세요.');

    try {
      const res = await get('/api/3/room/get-room-id', {
        seq: keyword,
        type: 0,
      });

      if (res.roomId) {
        const link = getRoomDetailLink({
          detailId: res.roomId,
          roomType: res.roomType,
          sellingType: res.sellingType,
          center: {
            lat: res.randomLocation?.[1],
            lng: res.randomLocation?.[0],
          },
        });

        history.push(link);

      } else {
        toast.error('검색된 매물이 없습니다. 매물번호를 다시 입력해주세요.');
      }

    } catch (err) {
      setKeyword('');

      const msg = getErrorMessage(err).msg;
      toast.error(msg);
    }
  }

  const checkIsSamePath = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
    if (location.pathname !== '/' && e.currentTarget.href.indexOf(location.pathname) > -1) {
      e.preventDefault();
    }
  }, [location]);

  return {
    keyword,
    onChange,
    onSubmit,
    checkIsSamePath,
  };
}

export default useSearch;
