import styled from 'styled-components';

export const Picker = styled.div`
  width: 100%;
  position: relative;
`;

export const CalendarWrap = styled.div`
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  z-index: 1;
`;
