import styled from 'styled-components';

export const Header = styled.div`
  padding: 24px 32px;
  border-bottom: 1px solid ${({ theme  }) => theme.color.gray[200]};
    
  > h1 {
    color: ${({ theme  }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body2};
  }
`;

export const Content = styled.div`
  padding: 32px 32px 40px;
`;

export const DescTitle = styled.h1`
  color: ${({ theme  }) => theme.color.gray[900]};
  ${({ theme }) => theme.font.body3};
`;

export const Desc = styled.p`
  color: ${({ theme  }) => theme.color.gray[700]};
  ${({ theme }) => theme.font.body3};
`;

export const PhoneInfo = styled.div`
  margin-top: 16px;
  padding: 16px 0;
  text-align: center;
  border-radius: 4px;
  background-color: ${({ theme  }) => theme.color.gray[100]};
  
  > p {
    color: ${({ theme  }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body3};
    
    > span {
      margin-left: 8px;
      color: ${({ theme  }) => theme.color.blue[500]};
      ${({ theme }) => theme.font.body3};
    }
  }
`;

export const BtnWrap = styled.div`
  padding-top: 32px;
  text-align: center;
  
  > button + button {
    margin-left: 8px;
  }
`;
