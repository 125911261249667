interface Props {
  // 0: 아파트, 1: 오피스텔
  complexType: 0 | 1;
  complexId: string;
  center: Map.Location;
}

/**
 * 단지의 거래가능한 방 선택
 * 결과 : 지도에서 해당 단지가 선택된 상태로 이동되는 링크 생성
 */
export function getComplexTradeableLink({
  complexType,
  complexId,
  center,
}: Props) {
  let mapCategory = '';
  switch (complexType) {
    case 0:
      mapCategory = 'apt';
      break;

    case 1:
      mapCategory = 'officetel';
      break;
  }

  const url = new URL(`${window.location.origin}/map/${mapCategory}`);

  url.searchParams.set('m_lat', center.lat.toString());
  url.searchParams.set('m_lng', center.lng.toString());
  url.searchParams.set('m_zoom', '16');
  url.searchParams.set('search_type', 'complex');
  url.searchParams.set('search_id', complexId);

  return `${url.pathname}${url.search}`;
}
