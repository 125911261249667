import React, { CSSProperties } from 'react';

import { useSearchHistoryStore } from '~/store/search-history';
import useSearchSend from '~/hooks/search/useSearchSend';

import { MAX_SCROLL_HEIGHT } from '../config';

import { TextBtn } from '~/atoms2';
import Scrollbar from 'web~/components/scrollbar2';

import RegionBtn from './btns/region';
import SubwayBtn from './btns/subway';
import UnivBtn from './btns/univ';
import ComplexBtn from './btns/complex';
import SaleInLotsBtn from './btns/sale-in-lots';

import { SearchResultContent, Header, Content } from './styled';

interface Props {
  clickClose: () => void;
  style?: CSSProperties;
}

function RecentComponent({
  clickClose,
  style,
}: Props) {
  const history = useSearchHistoryStore(store => store.history);
  const removeAllHistory = useSearchHistoryStore(store => store.removeAllHistory);

  const {
    clickRegion,
    clickSubway,
    clickUniv,
    clickComplex,
    clickSaleInLots,
  } = useSearchSend({
    callbackAfterSend: clickClose,
  });

  if (history.length <= 0) return null;

  return (
    <SearchResultContent style={style}>
      <Header>
        <h1>최근검색</h1>

        <TextBtn
          btnColor="gray"
          btnSize="sm"
          fontWeight="regular"
          onClick={removeAllHistory}
        >
          전체삭제
        </TextBtn>
      </Header>

      <Scrollbar
        containerId="search-recent-list"
        scrollbarSize={4}
        maxHeight={MAX_SCROLL_HEIGHT}
      >
        <Content>
          {history.map(h => {
            const id = `${h.type}_${h.id}`;

            if (h.type === 'region') {
              return (
                <RegionBtn
                  key={id}
                  item={h}
                  onClick={clickRegion}
                />
              );

            } else if (h.type === 'subway') {
              return (
                <SubwayBtn
                  key={id}
                  item={h}
                  onClick={clickSubway}
                />
              );

            } else if(h.type === 'univ') {
              return (
                <UnivBtn
                  key={id}
                  item={h}
                  onClick={clickUniv}
                />
              );

            } else if (h.type === 'officetel' || h.type === 'apt') {
              return (
                <ComplexBtn
                  key={id}
                  item={h}
                  onClick={clickComplex}
                />
              );

            } else if (h.type === 'sale-in-lots') {
              return (
                <SaleInLotsBtn
                  key={id}
                  item={h}
                  onClick={clickSaleInLots}
                />
              );
            }

            return null;
          })}
        </Content>
      </Scrollbar>
    </SearchResultContent>
  );
}

export default RecentComponent;
