import { useState } from 'react';
import { produce } from 'immer';

import type { S3ChangeEvent } from '~/atoms2';

import useApiCall from '~/hooks/useApiCall';

import checkEmail from '~/utils/valid/check-email';
import checkTel from '~/utils/valid/check-tel';
import phoneFormat from '~/utils/format/phone-format';
import getErrorMessage from '~/utils/get-error-message';

import { toast } from '~/components/toast';

export default function useAdInquiry(closePopup: () => void) {
  const [isSending, setSending] = useState(false);
  const [state, setState] = useState({
    adType: '',
    companyName: '',
    name: '',
    tel: '',
    email: '',
    adCreative: '',
    adHopeDate: '',
    isAgree: false,
  });

  const { post } = useApiCall();

  function changeState({ name, value }: S3ChangeEvent) {
    setState(produce(draft => {
      draft[name as 'adType' | 'companyName' | 'name' | 'email' | 'adCreative' | 'adHopeDate'] = value;
    }));
  }

  function changePhone({ value }: S3ChangeEvent) {
    setState(produce(draft => {
      draft.tel = value;
    }));
  }

  function blurPhone() {
    setState(produce(draft => {
      draft.tel = phoneFormat(draft.tel);
    }));
  }

  function focusPhone() {
    setState(produce(draft => {
      draft.tel = draft.tel.replace(/-/g, '');
    }));
  }

  function changeAgree() {
    setState(produce(draft => {
      draft.isAgree = !draft.isAgree;
    }));
  }

  function validate() {
    if (state.adType === '') {
      toast.error('광고유형을 선택해 주세요.');
      return false;
    }

    if (state.companyName === '') {
      toast.error('업체명을 입력해 주세요.');
      return false;
    }

    if (state.name === '') {
      toast.error('담당자명을 입력해 주세요.');
      return false;
    }

    if (state.tel === '') {
      toast.error('연락처를 입력해 주세요.');
      return false;
    }

    if (checkTel(state.tel)) {
      toast.error('연락처를 정확히 입력해 주세요.');
      return false;
    }

    if (state.email === '') {
      toast.error('이메일을 입력해 주세요.');
      return false;
    }

    if (checkEmail(state.email)) {
      toast.error('이메일 형식이 잘못되었습니다.');
      return false;
    }

    if (state.adCreative === '') {
      toast.error('광고소재를 입력해 주세요.');
      return false;
    }

    if (state.adHopeDate === '') {
      toast.error('광고희망시기를 입력해 주세요.');
      return false;
    }

    if (!state.isAgree) {
      toast.error('약관에 동의해 주세요.');
      return false;
    }

    return true;
  }

  async function submit() {
    if (isSending) return;
    if (!validate()) return;

    setSending(true);

    try {
      await post('/api/3/etc/ad/contact', {
        ad_type: state.adType,
        company_name: state.companyName,
        name: state.name,
        tel: state.tel.replace(/-/g, ''),
        email: state.email,
        ad_creative: state.adCreative,
        ad_hope_date: state.adHopeDate,
      });

      toast.success('문의 신청이 완료되었습니다.');
      closePopup();

    } catch (err) {
      const msg = getErrorMessage(err).msg;
      toast.error(msg);
      setSending(false);
    }
  }

  return {
    isSending,
    state,
    changeState,

    changePhone, blurPhone, focusPhone,
    changeAgree,
    submit,
  };
}
