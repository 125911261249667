import { useRef, useEffect, MouseEvent as ReactMouseEvent } from 'react';

interface Props {
  containerId: string;
}

function useDragThumb({
  containerId,
}: Props) {
  const _yScroll = useRef<HTMLDivElement>(null);
  const _xScroll = useRef<HTMLDivElement>(null);

  const _isDragging = useRef<boolean>(false);
  const _position = useRef<'x' | 'y' | null>(null);
  const _shift = useRef<number>(0);

  const _ro = useRef(new ResizeObserver(calculate));

  useEffect(() => {
    initObserver();
    initEvent();

    return () => {
      _ro.current?.disconnect();
      document.getElementById(containerId)?.removeEventListener('scroll', scrollEvent);
    };
  }, [containerId]);

  function initObserver() {
    const dom = document.getElementById(containerId);
    if (dom) {
      _ro.current.observe(dom);

      dom.childNodes.forEach(elem => {
        _ro.current.observe(elem as HTMLDivElement);
      });

    } else {
      setTimeout(() => {
        initObserver();
      }, 100);
    }
  }

  function initEvent() {
    const root = document.getElementById(containerId);

    if (root) {
      root.addEventListener('scroll', scrollEvent, false);

    } else {
      setTimeout(() => {
        initEvent();
      }, 100);
    }
  }

  function calculate() {
    const dom = document.getElementById(containerId) as HTMLDivElement;

    if (!dom) return;

    const scrollTop = dom.scrollTop;
    const scrollLeft = dom.scrollLeft;

    const clientWidth = dom.scrollWidth;
    const clientHeight = dom.scrollHeight;
    const innerWidth = dom.offsetWidth;
    const innerHeight = dom.offsetHeight;

    const thumbHeight = (innerHeight / clientHeight) * 100;
    const thumbWidth = (innerWidth / clientWidth) * 100;

    if (_yScroll.current) {
      if (thumbHeight >= 100) _yScroll.current.style.display = 'none';
      else _yScroll.current.style.display = 'block';

      _yScroll.current.style.height = `${thumbHeight}%`;
      _yScroll.current.style.top = `${(scrollTop / clientHeight) * 100}%`;
    }

    if (_xScroll.current) {
      if (thumbWidth >= 100) _xScroll.current.style.display = 'none';
      else _xScroll.current.style.display = 'block';

      _xScroll.current.style.width = `${thumbWidth}%`;
      _xScroll.current.style.left = `${(scrollLeft / clientWidth) * 100}%`;
    }
  }

  function scrollEvent() {
    const dom = document.getElementById(containerId) as HTMLDivElement;

    if (dom) {
      const scrollTop = dom.scrollTop;
      const scrollLeft = dom.scrollLeft;
      const clientWidth = dom.scrollWidth;
      const clientHeight = dom.scrollHeight;

      if (_yScroll.current) {
        _yScroll.current.style.top = `${(scrollTop / clientHeight) * 100}%`;
      }

      if (_xScroll.current) {
        _xScroll.current.style.left = `${(scrollLeft / clientWidth) * 100}%`;
      }
    }
  }

  function mouseDown(pos: 'x' | 'y', e: ReactMouseEvent<HTMLDivElement>) {
    e.preventDefault();
    document.addEventListener('mousemove', mouseMove, false);
    document.addEventListener('mouseup', mouseUp);

    _position.current = pos;
    _isDragging.current = true;

    if (pos === 'y' && _yScroll.current) {
      _shift.current = e.clientY - _yScroll.current.getBoundingClientRect().top;

    } else if (pos === 'x' && _xScroll.current) {
      _shift.current = e.clientX - _xScroll.current.getBoundingClientRect().left;
    }
  }

  function mouseUp() {
    document.removeEventListener('mousemove', mouseMove);
    document.removeEventListener('mouseup', mouseUp);

    _position.current = null;
    _isDragging.current = false;
  }

  function mouseMove(e: MouseEvent) {
    if (!_isDragging.current) return;

    const dom = document.getElementById(containerId) as HTMLDivElement;

    if (dom) {
      if (_position.current === 'x' && _xScroll.current) {
        const rect = _xScroll.current.getBoundingClientRect();

        const innerWidth = dom.offsetWidth;
        const maxXWidth = innerWidth - rect.width;
        const moveScrollX = e.clientX - _shift.current - rect.left; // 스크롤 Y축 이동 px

        if (maxXWidth < moveScrollX) return;

        const clientWidth = dom.scrollWidth;
        const perPx = innerWidth * (dom.scrollLeft / clientWidth);
        const scrollTop = dom.scrollTop;

        dom.scrollTo(clientWidth * ((moveScrollX + perPx) / innerWidth), scrollTop);

      } else if (_position.current === 'y' && _yScroll.current) {
        const rect = _yScroll.current.getBoundingClientRect();

        const innerHeight = dom.offsetHeight;
        const maxYHeight = innerHeight - rect.height;
        const moveScrollY = e.clientY - _shift.current - rect.top; // 스크롤 Y축 이동 px

        if (maxYHeight < moveScrollY) return;

        const clientHeight = dom.scrollHeight;
        const perPx = innerHeight * (dom.scrollTop / clientHeight);
        const scrollLeft = dom.scrollLeft;

        dom.scrollTo(scrollLeft, clientHeight * ((moveScrollY + perPx) / innerHeight));
      }
    }
  }

  return {
    calculate,
    _xScroll, _yScroll, mouseDown,
  };
}

export default useDragThumb;
