import React, { forwardRef, useImperativeHandle, UIEvent, ReactNode, CSSProperties } from 'react';

import useDragThumb from './useDragThumb';

import {
  Container, ScrollArea,
  ScrollTrackY, ScrollTrackX,
  ScrollThumbY, ScrollThumbX,
} from './styled';

export interface ScrollbarHandler {
  reCalculate: () => void;
}

interface Props {
  containerId: string;
  /** 스크롤 영역 최대 높이 */
  maxHeight?: number;
  /** 스크롤바 두께 px */
  scrollbarSize?: number;
  onScroll?: (e: UIEvent<HTMLDivElement>) => void;
  children: ReactNode | ReactNode[];
  style?: CSSProperties;
}

/**
 * @param containerId
 * @param maxHeight 스크롤 영역 최대 높이
 * @param scrollbarSize 스크롤바 두께 px
 * @param onScroll 스크롤 이벤트
 * @param children
 * @param style
 */
const ScrollbarComponent = forwardRef<ScrollbarHandler, Props>(({
  containerId,
  maxHeight,
  scrollbarSize = 6,
  onScroll,
  children,
  style,
}, ref) => {
  const {
    calculate,
    _xScroll, _yScroll, mouseDown,
  } = useDragThumb({
    containerId,
  });

  useImperativeHandle(ref, () => ({
    reCalculate: () => {
      calculate();
    },
  }));

  return (
    <Container style={style}>
      <ScrollTrackY
        hasId={!!containerId}
        scrollbarSize={scrollbarSize}
      >
        <ScrollThumbY
          ref={_yScroll}
          scrollbarSize={scrollbarSize}
          onMouseDown={mouseDown.bind(null, 'y')}
        />
      </ScrollTrackY>

      <ScrollTrackX
        hasId={!!containerId}
        scrollbarSize={scrollbarSize}
      >
        <ScrollThumbX
          ref={_xScroll}
          scrollbarSize={scrollbarSize}
          onMouseDown={mouseDown.bind(null, 'x')}
        />
      </ScrollTrackX>

      <ScrollArea
        id={containerId}
        maxHeight={maxHeight}
        onScroll={onScroll}
      >
        <div>
          {children}
        </div>
      </ScrollArea>
    </Container>
  );
});

export default ScrollbarComponent;
