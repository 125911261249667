import styled, { css } from 'styled-components';

import type { TColor, TSize, TFontWeight } from '../types';
import { getSize } from '../utils';

function getColor(color: TColor) {
  switch (color) {
    case 'blue':
      return css`
        > div {
          color: ${({ theme }) => theme.color.blue['500']};
        }
        
        @media (hover: hover) and (pointer: fine) {
          &:hover > div {
            border-bottom-color: ${({ theme }) => theme.color.blue['500']}; 
          }
        }
      `;

    case 'red':
      return css`
        > div {
          color: ${({ theme }) => theme.color.red['500']};
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover > div {
            border-bottom-color: ${({ theme }) => theme.color.red['500']};
          }
        }
      `;

    case 'gray':
      return css`
        > div {
          color: ${({ theme }) => theme.color.gray['900']};
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover > div {
            border-bottom-color: ${({ theme }) => theme.color.gray['900']};
          }
        }
      `;
  }

  return '';
}

export const textBtnStd = css<{
  contentWidth: string;
  btnColor: TColor;
  btnSize: TSize;
  fontWeight?: TFontWeight;
}>`
  flex: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  
  width: ${({ contentWidth }) => contentWidth};
  padding: 0;
  border: 0;
  background-color: transparent;
  transition: all 150ms ease-out;
  cursor: pointer;

  > div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    transition: border-bottom-color 150ms ease-out;
   
    > svg {
      flex: none;
    }
  }
  
  ${({ btnColor }) => getColor(btnColor)};
  ${({ btnSize, fontWeight }) => getSize(btnSize, fontWeight)};
  
  &:disabled {
    cursor: default;
    
    > div {
      color: ${({ theme }) => theme.color.gray[400]};
      border-bottom-color: transparent;
      
      > svg {
        circle[fill],
        ellipse[fill],
        line[fill],
        path[fill],
        polygon[fill],
        polyline[fill],
        rect[fill] {
          fill: ${({ theme }) => theme.color.gray[400]} !important;
        }

        circle[stroke],
        ellipse[stroke],
        line[stroke],
        path[stroke],
        polygon[stroke],
        polyline[stroke],
        rect[stroke] {
          stroke: ${({ theme }) => theme.color.gray[400]} !important;
        }
      }
    }
  }
`;

export const TextButton = styled.button`
  ${textBtnStd};
`;

export const Text = styled.span<{
  hasLeftIcon: boolean;
  hasRightIcon: boolean;
}>`
  margin-left: ${({ hasLeftIcon }) => hasLeftIcon ? '4px' : '0'};
  margin-right: ${({ hasRightIcon }) => hasRightIcon ? '4px' : '0'};
`;
