import styled from 'styled-components';

export const DescList = styled.ul`
  margin-bottom: 32px;
  
  > li {
    display: flex;
    column-gap: 4px;
    color: ${({ theme }) => theme.color.gray[700]};
    ${({ theme }) => theme.font.body3};
    
    &:before {
      flex: none;
      content: '-';
    }
  }
`;

export const CodeContainer = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.color.gray[50]};
`;

export const CodeContent = styled.div`
  display: flex;
  column-gap: 8px;
  width: 100%;
`;
