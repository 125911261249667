import React, { useState } from 'react';

import {
  FlexGroup,
  LabelGroup,
  Checkbox,
} from '~/atoms2';

import SvgIcon from '~/components/svg-icon';

import { TermContainer, TermBtn, Term, TermItem, DescItem } from './styled';

interface Props {
  isAgree: boolean;
  changeAgree: () => void;
}

function TermComponent({
  isAgree,
  changeAgree,
}: Props) {
  const [isShowTerm, setShowTerm] = useState(false);

  function termHandler() {
    setShowTerm(prev => !prev);
  }

  return (
    <TermContainer>
      <FlexGroup>
        <LabelGroup>
          <Checkbox
            checked={isAgree}
            onChange={changeAgree}
          >
            개인정보 수집·이용 동의 (필수)
          </Checkbox>
        </LabelGroup>

        <TermBtn onClick={termHandler}>
          {isShowTerm ? (
            <SvgIcon name="ic_24_arrow_up_gray700" />
          ) : (
            <SvgIcon name="ic_24_arrow_down_gray700" />
          )}
        </TermBtn>
      </FlexGroup>

      {isShowTerm && (
        <Term>
          <ul>
            <TermItem>
              <div>
                <h1>개인정보 수집 이용 목적</h1>
                <p>광고 문의에 따른 상담 안내</p>
              </div>
            </TermItem>

            <TermItem>
              <div>
                <h1>개인정보 수집 항목</h1>
                <p>광고유형, 업체명, 담당자명, 연락처, 이메일, 광고소재, 광고희망시기</p>
              </div>
            </TermItem>

            <TermItem>
              <div>
                <h1>개인정보의 보유 및 이용기간</h1>
                <p><b><u>접수일로부터 90일간</u> 보관 후 파기</b></p>
              </div>
            </TermItem>

            <DescItem>
              <div>
                <p>이용자는 개인정보 수집 및 이용을 거부할 권리가 있습니다.</p>
                <p>다만 개인정보 수집 및 이용을 거부하실 경우 광고 문의 이용이 제한됩니다.</p>
              </div>
            </DescItem>
          </ul>
        </Term>
      )}
    </TermContainer>
  );
}

export default TermComponent;
