interface Props {
  center: Map.Location;
  saleInLotsId: string;
}

export function getSaleInLotsDetailLink({
  center,
  saleInLotsId,
}: Props) {
  const url = new URL(`${window.location.origin}/map/sale-in-lots`);

  url.searchParams.set('m_lat', center.lat.toString());
  url.searchParams.set('m_lng', center.lng.toString());
  url.searchParams.set('m_zoom', '16');

  url.searchParams.set('search_type', 'sale-in-lots');
  url.searchParams.set('search_id', saleInLotsId);

  url.searchParams.set('detail_type', 'sale-in-lots');
  url.searchParams.set('detail_id', saleInLotsId);

  return `${url.pathname}${url.search}`;
}
