import { useRef, useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAtom } from 'jotai';
import { nanoid } from 'nanoid';

import type { RootState } from '~/store';
import type { S3ChangeEvent } from '~/atoms2';

import { safeAuthAtom } from '../../store';

import useApiCall from '~/hooks/useApiCall';
import { printError } from '~/utils/fetch';
import checkName from '~/utils/valid/check-name';
import checkBirth from '~/utils/valid/check-birth';
import checkCellPhone from '~/utils/valid/check-cell-phone';
import { phoneFormat } from '~/utils/format';

import { toast } from '~/components/toast';

function useForm() {
  const _isMount = useRef(false);

  const [isShowTerm, setShowTerm] = useState(false);
  const [state, setState] = useAtom(safeAuthAtom);
  const [isSending, setSending] = useState(false);

  const {
    isAuth,
    isSafeAuth,
  } = useSelector(({ user }: RootState) => user);

  const { get, post } = useApiCall();

  useEffect(() => {
    _isMount.current = true;
    getTerms();

    return () => {
      _isMount.current = false;
    };
  }, []);

  async function getTerms() {
    const url = isAuth && isSafeAuth ? '/api/3/terms/safe-auth/edit' : '/api/3/terms/safe-auth/add';

    try {
      const res = await get(url);

      if (!_isMount.current) return;

      const tempTerms = res.map((item: PopupSafeAuth.TermsResponse) => {
        return {
          id: nanoid(),
          required: item.required,
          url: item.url,
          termsHistorySeq: item.termsHistorySeq,
          termsName: `${item.required ? '[필수]' : '[선택]'} ${item.termsName}`,
          checked: false,
        };
      });

      setState(draft => {
        draft.termsList = tempTerms;
      });

    } catch (err) {
      printError(err);
    }
  }

  function changeName({ value }: S3ChangeEvent) {
    if (!/^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣 ]*$/gi.test(value)) return;

    const msg = checkName(value);

    setState(draft => {
      draft.name = value;
      draft.error.name = msg ? msg : '';
    });
  }

  function changeBirth({ value }: S3ChangeEvent) {
    const msg = checkBirth(value, state.genderNum);

    setState(draft => {
      draft.birth = value;
      draft.error.birth = msg.errorBirth ? msg.errorBirth : '';
      draft.error.genderNum = msg.errorGenderNum ? msg.errorGenderNum : '';
    });
  }

  function changeGenderNum({ value }: S3ChangeEvent) {
    const msg = checkBirth(state.birth, value);

    setState(draft => {
      draft.genderNum = value;
      draft.error.birth = msg.errorBirth ? msg.errorBirth : '';
      draft.error.genderNum = msg.errorGenderNum ? msg.errorGenderNum : '';
    });
  }

  function changeMobileCo({ value }: S3ChangeEvent) {
    setState(draft => {
      draft.mobileCo = value;
    });
  }

  function changePhone({ value }: S3ChangeEvent) {
    const msg = checkCellPhone(value);

    setState(draft => {
      draft.phone = value;
      draft.error.phone = msg ? msg : '';
    });
  }

  function blurPhone() {
    setState(draft => {
      draft.phone = phoneFormat(draft.phone);
    });
  }

  function focusPhone() {
    setState(draft => {
      draft.phone = draft.phone.replace(/-/g, '');
    });
  }

  const isFormDone = useMemo(() => {
    if (state.error.name !== '') return false;
    if (state.error.birth !== '') return false;
    if (state.error.genderNum !== '') return false;
    if (state.mobileCo === '') return false;
    if (state.error.phone !== '') return false;
    return true;
  }, [state.error, state.mobileCo]);

  // 다음 클릭
  function openTerms() {
    if (!isFormDone) return;
    setShowTerm(true);
  }

  function closeTerms() {
    if (isSending) return;

    setState(draft => {
      draft.termsList = draft.termsList.map(term => ({
        ...term,
        checked: false,
      }));
    });

    setShowTerm(false);
  }

  /**
   * 전체 (필수)약관 동의 여부
   */
  const isCheckedEveryRequired = useMemo(() => {
    return state.termsList.length > 0 && state.termsList.every(term => term.required ? term.checked : true);
  }, [state.termsList]);

  /**
   * 동의하고 인증하기 클릭
   */
  async function submit() {
    if (isSending) return;
    if (!isCheckedEveryRequired) return;

    setSending(true);

    try {
      const res = await post(`/api/3/safe-auth/check/step1`, {
        name: state.name,
        jumin: `${state.birth}${state.genderNum}`,
        mobile_co: state.mobileCo,
        mobile_no: state.phone.replace(/-/g, ''),
      });

      if (!_isMount.current) return;

      if (res?.result) {
        setState(draft => {
          draft.step = 'CODE';
          draft.requestSeq = res.requestSeq;
        });

      } else {
        toast.error(res?.message ?? '서버에러가 발생했습니다.');
        setSending(false);
        setShowTerm(false);
        setState(draft => {
          draft.termsList = draft.termsList.map(term => ({
            ...term,
            checked: false,
          }));
        });
      }

    } catch (err) {
      printError(err);
      if (!_isMount.current) return;
      setSending(false);
    }
  }

  return {
    state,
    changeName,
    changeBirth,
    changeGenderNum,
    changeMobileCo,
    changePhone, blurPhone, focusPhone,

    isFormDone, isShowTerm, isCheckedEveryRequired,
    openTerms, closeTerms,
    isSending, submit,
  };
}

export default useForm;
