import styled, { css } from 'styled-components';

export const TextareaWrap = styled.div<{
  $contentWidth: string;
}>`
  width: ${({ $contentWidth }) => $contentWidth};
`;

export const Content = styled.div<{
  $contentHeight: string;
  $isDisabled: boolean;
}>`
  width: 100%;
  height: ${({ $contentHeight }) => $contentHeight};
  position: relative;

  ${({ $isDisabled }) => $isDisabled && css`
    > button,
    > div {
      > svg {
        circle[fill],
        ellipse[fill],
        line[fill],
        path[fill],
        polygon[fill],
        polyline[fill],
        rect[fill] {
          fill: ${({ theme }) => theme.color.gray[500]} !important;
        }
  
        circle[stroke],
        ellipse[stroke],
        line[stroke],
        path[stroke],
        polygon[stroke],
        polyline[stroke],
        rect[stroke] {
          stroke: ${({ theme }) => theme.color.gray[500]} !important;
        }
      }
    }
    
    & + div {
      .helper-text,
      .unit {
        color: ${({ theme }) => theme.color.gray[500]};
      }
    }
  `}
`;

export const TextArea = styled.textarea<{
  $hasIcon: boolean;
  $isError: boolean;
}>`
  width: 100%;
  height: 100%;
  padding: ${({ $hasIcon }) => `10px 16px 10px ${$hasIcon ? '44px' : '16px'}`};
  color: ${({ theme }) => theme.color.gray[900]};
  ${({ theme }) => theme.font.body3};
  border: 1px solid ${({ $isError, theme }) => $isError ? theme.color.red[500] : theme.color.gray[300]};
  border-radius: 2px;
  background-color: #fff;
  transition: all 150ms ease-out;
  resize: none;

  &.left {
    text-align: left;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }

  &:focus {
    border-color: ${({ $isError, theme }) => $isError ? theme.color.red[500] : theme.color.gray[900]};
  }

  &::placeholder {
    color: ${({ theme }) => theme.color.gray[600]};
  }

  &:disabled {
    color: ${({ theme }) => theme.color.gray[500]};
    border-color: ${({ theme }) => theme.color.gray[300]};
    background-color: ${({ theme }) => theme.color.gray[50]};

    &::placeholder {
      color: ${({ theme }) => theme.color.gray[500]};
      user-select: none;
    }
  }
`;

const leftIcon = css`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  left: 8px;
`;

export const LeftIconWrap = styled.div`
  ${leftIcon};
  pointer-events: none;
`;

export const LeftIconBtn = styled.button.attrs({
  type: 'button'
})`
  ${leftIcon};
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
`;

export const BottomContent = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 8px;
`;

export const HelperText = styled.p.attrs({
  className: 'helper-text',
})<{
  $isError?: boolean;
}>`
  flex-grow: 1;
  padding-right: 8px;
  color: ${({ $isError, theme }) => $isError ? theme.color.red[500] : theme.color.gray[900]};
  ${({ theme }) => theme.font.body3};
  white-space: pre-wrap;
  word-break: break-all;
`;

export const Unit = styled.p.attrs({
  className: 'unit',
})<{
  $isActive: boolean;
  $isError?: boolean;
}>`
  flex: none;
  margin-left: auto;
  color: ${({ $isActive, $isError, theme }) => $isError ? theme.color.red[500] : $isActive ? theme.color.gray[900] : theme.color.gray[600]};
  ${({ theme }) => theme.font.body3};
  white-space: nowrap;
  word-break: keep-all;
  pointer-events: none;
  user-select: none;
`;
