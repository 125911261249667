import axios from 'axios';
import type { AxiosInstance, AxiosError, InternalAxiosRequestConfig, Canceler } from 'axios';
import qs from 'query-string';

import deleteBlank from '../delete-blank';

export type RequestConfig = InternalAxiosRequestConfig;
export type { Canceler };
export type CancelCallback = (c: Canceler) => void;

export interface FetchConfig {
  cancel?: CancelCallback;
}

export interface ReturnReject {
  status: number;
  code: number | null;
  msg: string;
}

interface Props {
  baseURL?: string;
  headers?: Record<string, string>;
  requestHeaders?: () => Record<string, string>;
  responseEntryKeyName?: string;
  paramsSerializer?(params: Record<string, any>): Record<string, any>;
  requestHandler?(config: RequestConfig): RequestConfig;
  errorHandler?(err: ReturnReject): ReturnReject;
}

class CreateFetch {
  private readonly instance: AxiosInstance;
  private cancelSource = axios.CancelToken.source();
  private readonly requestHeaders: () => Record<string, string> = () => ({});
  private readonly responseEntryKeyName: string = '';

  constructor(props: Props) {
    if (props.requestHeaders) {
      this.requestHeaders = props.requestHeaders;
    }

    if (props.responseEntryKeyName) {
      this.responseEntryKeyName = props.responseEntryKeyName;
    }

    this.instance = axios.create({
      baseURL: props.baseURL,
      headers: {
        csrf: 'token',
        Accept: 'application/json, text/plain, */*',
        Pragma: 'no-cache',
        Expires: -1,
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        ...(props.headers ?? {}),
      },
      cancelToken: this.cancelSource.token,
      paramsSerializer: {
        serialize: params => {
          if (props?.paramsSerializer) {
            params = props.paramsSerializer(params);
          }
          return qs.stringify(params);
        }
      },
    });

    // 요청시 보내기 전 호출 되는 함수
    function requestHandler(config: RequestConfig) {
      if (config.method) {
        if (['post', 'put', 'patch'].includes(config.method)) {
          config.data = deleteBlank(config.data);

        } else if (['get', 'delete'].includes(config.method)) {
          config.params = deleteBlank(config.params);
        }
      }

      if (props?.requestHandler) {
        config = props.requestHandler(config);
      }

      return config;
    }

    // 리스폰스가 실패했을때 호출되는 함수
    function responseRejectHandler(error: AxiosError<any>) {
      let err: ReturnReject = {
        status: error.response?.status ?? 0,
        code: null,
        msg: '서버에러가 발생하였습니다.',
      };

      if (error.response?.data) {
        const data = error.response?.data;

        if (data.code) {
          err.code = data.code;
        }

        if (data.msg) {
          err.msg = data.msg;
        }
      }

      if (axios.isCancel(error)) {
        console.log('API 요청이 취소되었습니다.');
        err.msg = '';
        return Promise.reject(err);
      }

      if (props?.errorHandler) {
        err = props.errorHandler(err);
      }

      return Promise.reject(err);
    }

    this.instance.interceptors.request.use(requestHandler);
    this.instance.interceptors.response.use(res => res, responseRejectHandler);
  }

  cancel() {
    this.cancelSource.cancel('요청이 취소되었습니다.');
  }

  async get<T = any>(url: string, params = {}, config?: FetchConfig) {
    const response = await this.instance({
      method: 'GET',
      url,
      params,
      cancelToken: config?.cancel ? new axios.CancelToken(config.cancel) : undefined,
      headers: this.requestHeaders(),
      responseType: 'json',
    });

    if (this.responseEntryKeyName) {
      return Promise.resolve<T>(response.data[this.responseEntryKeyName]);
    }

    return Promise.resolve<T>(response.data);
  }

  async post<T = any>(url: string, data = {}, config?: FetchConfig) {
    const response = await this.instance({
      method: 'POST',
      url,
      data,
      cancelToken: config?.cancel ? new axios.CancelToken(config.cancel) : undefined,
      headers: this.requestHeaders(),
      responseType: 'json',
    });

    if (this.responseEntryKeyName) {
      return Promise.resolve<T>(response.data[this.responseEntryKeyName]);
    }

    return Promise.resolve<T>(response.data);
  }

  async put<T = any>(url: string, data = {}, config?: FetchConfig) {
    const response = await this.instance({
      method: 'PUT',
      url,
      data,
      cancelToken: config?.cancel ? new axios.CancelToken(config.cancel) : undefined,
      headers: this.requestHeaders(),
      responseType: 'json',
    });

    if (this.responseEntryKeyName) {
      return Promise.resolve<T>(response.data[this.responseEntryKeyName]);
    }

    return Promise.resolve<T>(response.data);
  }

  async patch<T = any>(url: string, data = {}, config?: FetchConfig) {
    const response = await this.instance({
      method: 'PATCH',
      url,
      data,
      cancelToken: config?.cancel ? new axios.CancelToken(config.cancel) : undefined,
      headers: this.requestHeaders(),
      responseType: 'json',
    });

    if (this.responseEntryKeyName) {
      return Promise.resolve<T>(response.data[this.responseEntryKeyName]);
    }

    return Promise.resolve<T>(response.data);
  }

  async delete<T = any>(url: string, params = {}, config?: FetchConfig) {
    const response = await this.instance({
      method: 'DELETE',
      url,
      params,
      cancelToken: config?.cancel ? new axios.CancelToken(config.cancel) : undefined,
      headers: this.requestHeaders(),
      responseType: 'json',
    });

    if (this.responseEntryKeyName) {
      return Promise.resolve<T>(response.data[this.responseEntryKeyName]);
    }

    return Promise.resolve<T>(response.data);
  }
}

export default CreateFetch;
