import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface ModalState {
  modals: string[];
  originOverflow: string;
  pushModal: (modalId: string) => void;
  popModal: (modalId: string) => void;
}

const useModalStore = create<ModalState>()(
  devtools((set, get) => ({
    modals: [],
    originOverflow: '',
    pushModal: modalId => {
      const modals = get().modals;

      let originOverflow = get().originOverflow;

      if (modals.length === 0) {
        originOverflow = window.getComputedStyle(document.body).overflow;
        document.body.style.overflow = 'hidden';
      }

      (document.activeElement as HTMLElement)?.blur();

      set(state => ({
        modals: [...state.modals, modalId],
        originOverflow,
      }));
    },
    popModal: modalId => {
      const modals = get().modals;

      if (modals.length === 1) {
        document.body.style.overflow = get().originOverflow;
      }

      set(state => ({
        modals: state.modals.filter(id => id !== modalId),
      }));
    },
  }))
);

export default useModalStore;
