import React, { useMemo } from 'react';
import { useAtom } from 'jotai';

import type { S3ChangeEvent } from '~/atoms2';

import { safeAuthAtom } from '../../../store';

import { Checkbox } from '~/atoms2';

import { ReactComponent as CloseIcon } from './images/ic_24_close_big_gray900.svg';
import { ReactComponent as ExclamationIcon } from './images/ic_24_exclamation_mark_Gray900_solid.svg';
import { ReactComponent as ArrowRightIcon } from './images/ic_24_arrow_right_gray900.svg';
import {
  TermsContainer, Header, Desc,
  AllCheckContainer, TermList,
} from './styled';

interface Props {
  closeTerms: () => void;
}

function TermsComponent({
  closeTerms,
}: Props) {
  const [{ termsList }, setState] = useAtom(safeAuthAtom);

  /**
   *  전체 (필수, 선택)약관 동의 여부
   */
  const isCheckedAll = useMemo(() => {
    return termsList.every(term => term.checked);
  }, [termsList]);

  function changeAllTerms({ value }: S3ChangeEvent<boolean>) {
    setState(draft => {
      draft.termsList = draft.termsList.map(term => ({
        ...term,
        checked: value,
      }));
    });
  }

  function changeTerms(id: string, { value }: S3ChangeEvent<boolean>) {
    setState(draft => {
      const idx = draft.termsList.findIndex(i => i.id === id);

      if (idx > -1) {
        draft.termsList[idx].checked = value;
      }
    });
  }

  return (
    <TermsContainer>
      <Header>
        <ExclamationIcon />

        <h1>인증 전 확인해주세요</h1>

        <button onClick={closeTerms}>
          <CloseIcon />
        </button>
      </Header>

      <Desc>해당 약관에 대해서 동의하십니까?</Desc>

      <AllCheckContainer>
        <Checkbox
          checked={isCheckedAll}
          onChange={changeAllTerms}
        >
          <b>약관에 모두 동의합니다.</b>
        </Checkbox>
      </AllCheckContainer>

      <TermList>
        {termsList.map(item => {
          return (
            <li key={item.id}>
              <Checkbox
                checked={item.checked}
                onChange={changeTerms.bind(null, item.id)}
              >
                {item.termsName}
              </Checkbox>
              <a href={item.url} target="_blank" rel="noreferrer noopener">
                <ArrowRightIcon />
              </a>
            </li>
          );
        })}
      </TermList>
    </TermsContainer>
  );
}

export default TermsComponent;
