import { getMapCategory } from './get-map-category';

interface Props {
  center: Map.Location;
  roomType: 0 | 1 | 2 | 3 | 4 | 'ONE_ROOM' | 'TWO_ROOM' | 'THREE_ROOM' | 'OFFICETEL' | 'APT';
  sellingType: 0 | 1 | 2 | 'MONTHLY_RENT' | 'LEASE' | 'SELL';
  detailId: string;
}

export function getRoomDetailLink({
  center,
  roomType,
  sellingType,
  detailId,
}: Props) {
  const mapCategory = getMapCategory({
    roomType,
    sellingType,
  });

  const url = new URL(`${window.location.origin}/map/${mapCategory}`);

  url.searchParams.set('m_lat', center.lat.toString());
  url.searchParams.set('m_lng', center.lng.toString());
  url.searchParams.set('m_zoom', '16');
  url.searchParams.set('detail_type', 'room');
  url.searchParams.set('detail_id', detailId);

  return `${url.pathname}${url.search}`;
}
