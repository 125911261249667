import styled from 'styled-components';

export const TermsContainer = styled.div`
  width: 380px;
  padding: 24px;
  text-align: left;
  border: 1px solid ${({ theme }) => theme.color.gray[400]};
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 50%;
  bottom: calc(100% + 16px);
  transform: translateX(-50%);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 8px;
  
  > svg {
    flex: none;
  }
  
  > h1 {
    flex-grow: 1;
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body2};
  }
  
  > button {
    flex: none;
    width: 24px;
    height: 24px;
    margin-left: auto;
    padding: 0;
    border: 0;
    background-color: transparent;
  }
`;

export const Desc = styled.p`
  color: ${({ theme }) => theme.color.gray[700]};
  ${({ theme }) => theme.font.body3};
`;

export const AllCheckContainer = styled.div`
  margin-top: 32px;
`;

export const TermList = styled.ul`
  display: grid;
  grid-row-gap: 8px;
  margin-top: 16px;
  
  > li {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 28px;
    
    > a {
      height: 24px;
      text-align: right;
    }
  }
`;
