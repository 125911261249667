import React from 'react';
import { useAtomValue } from 'jotai';

import { safeAuthAtom } from '../../store';

import { LineBtn } from '~/atoms2';
import { PopupContent, PopupBtnContainer } from '~/components/modal2/modules';

import { Desc, CodeContainer } from './styled';

interface Props {
  closePopup: () => void;
}

function AlreadyInUseComponent({
  closePopup,
}: Props) {
  const {
    certError,
  } = useAtomValue(safeAuthAtom);
  return (
    <>
      <PopupContent>
        <Desc>{certError.message}</Desc>

        {certError.email !== '' && (
          <CodeContainer>
            <p>{certError.email}</p>
          </CodeContainer>
        )}
      </PopupContent>

      <PopupBtnContainer>
        <LineBtn
          contentWidth="120px"
          btnColor="gray"
          btnSize="xl"
          onClick={closePopup}
        >
          확인
        </LineBtn>
      </PopupBtnContainer>
    </>
  );
}

export default AlreadyInUseComponent;
