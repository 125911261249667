import { getComplexCategory } from './get-complex-category';

interface Props {
  center: Map.Location;
  complexId: string;
  complexType: 0 | 1;
}

export function getComplexDetailLink({
  center,
  complexId,
  complexType,
}: Props) {
  const mapCategory = getComplexCategory({ complexType });

  const url = new URL(`${window.location.origin}/map/${mapCategory}`);

  url.searchParams.set('m_lat', center.lat.toString());
  url.searchParams.set('m_lng', center.lng.toString());
  url.searchParams.set('m_zoom', '16');
  url.searchParams.set('search_type', 'complex');
  url.searchParams.set('search_id', complexId);
  url.searchParams.set('detail_type', 'complex');
  url.searchParams.set('detail_id', complexId);

  return `${url.pathname}${url.search}`;
}
