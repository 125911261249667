interface Props {
  roomType: 0 | 1 | 2 | 3 | 4 | 'ONE_ROOM' | 'TWO_ROOM' | 'THREE_ROOM' | 'OFFICETEL' | 'APT';
  sellingType: 0 | 1 | 2 | 'MONTHLY_RENT' | 'LEASE' | 'SELL';
}

export function getMapCategory({
  roomType, sellingType,
}: Props) {
  switch (roomType) {
    case 0:
    case 1:
    case 'ONE_ROOM':
    case 'TWO_ROOM':
      if (sellingType === 2 || sellingType === 'SELL') return 'house';
      return 'onetwo';

    case 2:
    case 'THREE_ROOM':
      return 'house';

    case 3:
    case 'OFFICETEL':
      return 'officetel';

    case 4:
    case 'APT':
      return 'apt';

    default:
      return '';
  }
}
