import React, { CSSProperties } from 'react';

import useSearchSend from '~/hooks/search/useSearchSend';

import { MAX_SCROLL_HEIGHT } from '../config';
import HighlightWords from '~/components/highlight-words';
import Scrollbar from 'web~/components/scrollbar2';

import { SearchResultContent, Name, Address } from '../styled';
import { Content, ComplexBtn, TextTag } from './styled';

interface Props {
  keywords: string[];
  complex: Search.SearchItem[];
  clickClose(): void;
  style?: CSSProperties;
}

function ComplexComponent({
  keywords,
  complex,
  clickClose,
  style,
}: Props) {
  const {
    clickComplex,
  } = useSearchSend({
    callbackAfterSend: clickClose,
  });

  if (complex.length <= 0) return null;

  return (
    <SearchResultContent style={style}>
      <h1>단지</h1>

      <Scrollbar
        containerId="search-complex-list"
        scrollbarSize={4}
        maxHeight={MAX_SCROLL_HEIGHT}
      >
        <Content>
          {complex.map(c => {
            return (
              <ComplexBtn
                key={c.id}
                onClick={clickComplex.bind(null, c)}
              >
                <div>
                  <Name>
                    <HighlightWords
                      text={c.name}
                      searchWords={keywords}
                    />
                  </Name>
                  <Address>
                    <HighlightWords
                      text={c.address ?? ''}
                      searchWords={keywords}
                    />
                  </Address>
                </div>

                <div>
                  {c.child?.map((i, key) => {
                    return (
                      <TextTag key={key} $color={i.color}>
                        {i.name}
                      </TextTag>
                    );
                  })}
                </div>
              </ComplexBtn>
            );
          })}
        </Content>
      </Scrollbar>
    </SearchResultContent>
  );
}

export default ComplexComponent;
