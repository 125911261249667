import React, { ReactNode } from 'react';

import { CTextBtn } from '~/atoms2';

import { BtnWrap, Divider } from './styled';

interface BtnItem {
  name: string;
  click: () => void;
}

interface Props {
  submitBtn?: BtnItem
  closeBtn?: BtnItem;
}

/**
 * submit, closePopup 둘다 최종적으로는 팝업을 닫는 동작을 해야한다.
 */
export function AlertBtnContainerComponent({
  submitBtn,
  closeBtn,
}: Props) {
  let btns: ReactNode[] = [];

  if (closeBtn) {
    btns.push(
      <CTextBtn
        key="close"
        btnColor="gray"
        btnSize="xl"
        onClick={closeBtn.click}
      >
        {closeBtn.name}
      </CTextBtn>
    );
  }

  if (submitBtn) {
    btns.push(
      <CTextBtn
        key="submit"
        btnColor="blue"
        btnSize="xl"
        onClick={submitBtn.click}
      >
        {submitBtn.name}
      </CTextBtn>
    );
  }

  const btn = btns.map<ReactNode>(str => {
    return str;
  }).reduce((prev, curr, idx) => [prev, <Divider key={idx}/>, curr]);

  return (
    <BtnWrap $btnCnt={btns.length}>
      {btn}
    </BtnWrap>
  );
}

AlertBtnContainerComponent.displayName = 'AlertBtnContainer';
