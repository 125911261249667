import styled from 'styled-components';

export const TermContainer = styled.div`
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid ${({ theme }) => theme.color.gray[300]};
`;

export const TermBtn = styled.button`
  flex: none;
  width: 24px;
  height: 24px;
  margin-left: auto;
  padding: 0;
  border: 0;
  background-color: transparent;
`;

export const Term = styled.div`
  margin-top: 8px;

  > ul {
    display: grid;
    grid-gap: 8px 0;
    padding: 16px;
    border: 1px solid ${({ theme }) => theme.color.gray[300]};
    border-radius: 2px;

    > li {
      ${({ theme }) => theme.font.caption1};

      > p {
        color: ${({ theme }) => theme.color.gray[600]};
      }
      > h1 {
        color: ${({ theme }) => theme.color.gray[900]};
      }
    }
  }
`;

export const TermItem = styled.li`
  display: flex;
  
  &:before {
    flex: none;
    display: block;
    width: 12px;
    content: '・';
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.caption1};
    font-weight: 500;
  }
  
  > div {
    flex-grow: 1;
    
    > h1 {
      color: ${({ theme }) => theme.color.gray[900]};
      ${({ theme }) => theme.font.caption1};
      font-weight: 400;
    }
    
    > p {
      color: ${({ theme }) => theme.color.gray[700]};
      ${({ theme }) => theme.font.caption1};
    }
  }
`;

export const DescItem = styled.li`
  display: flex;

  &:before {
    flex: none;
    content: '*';
    display: block;
    width: 12px;
    padding-right: 2px;
    color: ${({ theme }) => theme.color.gray[600]};
    ${({ theme }) => theme.font.caption1};
    text-align: right;
    box-sizing: border-box;
  }

  > div {
    flex-grow: 1;
    
    > p {
      color: ${({ theme }) => theme.color.gray[600]};
      ${({ theme }) => theme.font.caption1};
    }
  }
`;
