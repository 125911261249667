import React, { ReactNode } from 'react';

import { Content } from './styled';

interface Props {
  children: ReactNode | ReactNode[];
}

export function AlertContentModuleComponent({
  children,
}: Props) {
  return (
    <Content>
      {children}
    </Content>
  );
}

AlertContentModuleComponent.displayName = 'AlertContent';
