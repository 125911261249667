import React, { ReactNode } from 'react';

import { Content } from './styled';

interface Props {
  children: ReactNode | ReactNode[];
}

export function PopupContentModuleComponent({
  children,
}: Props) {
  return (
    <Content>
      {children}
    </Content>
  );
}

PopupContentModuleComponent.displayName = 'PopupContent';
