import { atomWithImmer } from 'jotai-immer';

export const safeAuthAtom = atomWithImmer<PopupSafeAuth.State>({
  step: 'FORM',
  name: '',
  birth: '',
  genderNum: '',
  mobileCo: '',
  phone: '',
  requestSeq: '',
  termsList: [],

  error: {
    name: '',
    birth: '',
    genderNum: '',
    phone: '',
  },

  certError: {
    email: '',
    message: '',
  },
});
