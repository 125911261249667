import React, { useEffect } from 'react';
import { Provider, useAtomValue } from 'jotai';
import Cookie from 'js-cookie';

import { safeAuthAtom } from './store';

import { Popup } from '~/components/modal2';
import { PopupHeader } from '~/components/modal2/modules';

import Form from './components/form';
import Code from './components/code';
import AlreadyInUse from './components/already-in-use';

interface Props {
  callback: PopupSafeAuth.TSafeAuthCallback;
  closePopup: () => void;
}

function SafeAuthPopup({
  callback,
  closePopup,
}: Props) {
  const { step } = useAtomValue(safeAuthAtom);

  useEffect(() => {
    Cookie.remove('safeAuthConfirm');

    return ()=> {
      Cookie.remove('safeAuthConfirm');
    };
  }, []);

  return (
    <Popup
      popupWidth={480}
      closePopup={closePopup}
      escToClose={false}
    >
      <PopupHeader
        title="본인인증"
        closePopup={closePopup}
      />

      {step === 'CODE' ? (
        <Code callback={callback} />
      ) : step === 'ALREADY_IN_USE' ? (
        <AlreadyInUse closePopup={closePopup} />
      ) : (
        <Form closePopup={closePopup} />
      )}
    </Popup>
  );
}

function SafeAuthPopupProvider(props: Props) {
  return (
    <Provider>
      <SafeAuthPopup {...props} />
    </Provider>
  );
}

export default SafeAuthPopupProvider;
