import React from 'react';

import useForm from './useForm';

import { Group, TextInput, NumberInput, Select, LineBtn, SolidBtn } from '~/atoms2';
import { PopupContent, PopupBtnContainer } from '~/components/modal2/modules';

import Terms from './terms';

import { Desc, Dash, SecretText, PhoneContent } from './styled';

interface Props {
  closePopup: () => void;
}

function FormComponent({
  closePopup,
}: Props) {
  const {
    state,
    changeName,
    changeBirth,
    changeGenderNum,
    changeMobileCo,
    changePhone, blurPhone, focusPhone,

    isFormDone, isShowTerm, isCheckedEveryRequired,
    openTerms, closeTerms,
    isSending, submit,
  } = useForm();

  return (
    <>
      <PopupContent>
        <Desc>본인인증을 위해 아래 정보를 입력해주세요.</Desc>

        <Group
          title="이름"
          isError={!!state.error.name}
          msg={state.error.name}
          style={{ marginBottom: '24px' }}
        >
          <TextInput
            contentWidth="100%"
            isError={!!state.error.name}
            max={40}
            hideMax
            value={state.name}
            onChange={changeName}
            placeholder="이름 입력"
          />
        </Group>

        <Group
          title="주민등록번호"
          isError={!!state.error.birth || !!state.error.genderNum}
          msg={state.error.birth || state.error.genderNum}
          style={{ marginBottom: '24px' }}
        >
          <NumberInput
            contentWidth="218px"
            isError={!!state.error.birth}
            max={6}
            value={state.birth}
            onChange={changeBirth}
            placeholder="주민번호 앞 6자리"
          />

          <Dash />

          <NumberInput
            contentWidth="44px"
            isError={!!state.error.genderNum}
            max={1}
            value={state.genderNum}
            onChange={changeGenderNum}
          />

          <SecretText>●●●●●●</SecretText>
        </Group>

        <PhoneContent>
          <Group title="통신사">
            <Select
              value={state.mobileCo}
              onChange={changeMobileCo}
            >
              <option>통신사</option>
              {[
                { label: 'SKT', type: 1 },
                { label: 'KT', type: 2 },
                { label: 'LG U+', type: 3 },
                { label: '알뜰 SKT', type: 5 },
                { label: '알뜰 KT', type: 6 },
                { label: '알뜰 LG U+', type: 7 },
              ].map(item => {
                return (
                  <option key={item.type} value={item.type}>
                    {item.label}
                  </option>
                );
              })}
            </Select>
          </Group>

          <Group
            title="휴대폰번호"
            isError={!!state.error.phone}
            msg={state.error.phone}
          >
            <NumberInput
              isError={!!state.error.phone}
              max={11}
              value={state.phone}
              onChange={changePhone}
              onBlur={blurPhone}
              onFocus={focusPhone}
              placeholder="‘-’ 없이 숫자만 입력"
            />
          </Group>
        </PhoneContent>
      </PopupContent>

      <PopupBtnContainer>
        {isShowTerm && (
          <Terms closeTerms={closeTerms} />
        )}

        <LineBtn
          contentWidth="120px"
          btnColor="gray"
          btnSize="xl"
          onClick={closePopup}
        >
          취소
        </LineBtn>

        <SolidBtn
          contentWidth="120px"
          btnColor="blue"
          btnSize="xl"
          onClick={isShowTerm ? submit : openTerms}
          disabled={isShowTerm ? !isCheckedEveryRequired || isSending : !isFormDone}
        >
          {isShowTerm ? '동의하고 인증' : '다음'}
        </SolidBtn>
      </PopupBtnContainer>
    </>
  );
}

export default FormComponent;
