import React, { useMemo, CSSProperties } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { getRoomDetailLink } from '~/utils/map/link';

import { LineBtn } from '~/atoms2';

import { SearchResultContent, Content, Seq } from './styled';

interface Props {
  roomSeq: string;
  room: Search.Room;
  style?: CSSProperties;
  clickClose(): void;
}

function RoomSeqComponent({
  roomSeq,
  room,
  style,
  clickClose,
}: Props) {
  const location = useLocation();
  const history = useHistory();

  const isMap = useMemo(() => {
    return location.pathname.indexOf('/map') === 0;
  }, [location.pathname]);

  function clickDetailLink() {
    const link = getRoomDetailLink({
      detailId: room.id,
      roomType: room.roomType,
      sellingType: room.sellingType,
      center: {
        lat: room.randomLocation?.[1],
        lng: room.randomLocation?.[0],
      },
    });

    const url = new URL(window.location.origin + link);

    let method: 'push' | 'replace' = 'push';
    if (isMap) {
      if (url.pathname === location.pathname) {
        method = 'replace';
      }
    }

    history[method](link);
    clickClose();
  }

  return (
    <SearchResultContent style={style}>
      <h1>일반매물</h1>

      <Content>
        <Seq>{roomSeq}</Seq>

        <LineBtn
          btnColor="blue"
          btnSize="sm"
          onClick={clickDetailLink}
        >
          매물정보 보러가기
        </LineBtn>
      </Content>
    </SearchResultContent>
  );
}

export default RoomSeqComponent;
