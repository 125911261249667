import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex: none;
  width: 100%;
  height: 100%;
  position: relative;
  overscroll-behavior-y: contain;
  z-index: 0;
`;

export const ScrollArea = styled.div<{ maxHeight?: number; }>`
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none; // IE에서 스크롤바 감춤
  scroll-behavior: smooth;
  
  ${({ maxHeight }) => maxHeight !== undefined && css`
    max-height: ${maxHeight}px;
  `};
  
  > div {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;

  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export const ScrollTrackY = styled.aside<{ hasId: boolean; scrollbarSize: number; }>`
  width: 0;
  background-color: transparent;
  position: ${({ hasId }) => hasId ? 'absolute' : 'fixed'};
  top: ${({ scrollbarSize }) => scrollbarSize}px;
  right: 0;
  bottom: ${({ scrollbarSize }) => scrollbarSize}px;
  z-index: 10;
`;

export const ScrollTrackX = styled.aside<{ hasId: boolean; scrollbarSize: number; }>`
  height: 0;
  background-color: transparent;
  position: ${({ hasId }) => hasId ? 'absolute' : 'fixed'};
  left: ${({ scrollbarSize }) => scrollbarSize + 2}px;
  right: ${({ scrollbarSize }) => scrollbarSize + 2}px;
  bottom: 0;
  z-index: 10;
`;

const thumbStd = css<{ scrollbarSize: number; }>`
  display: none;
  border-radius: 6px;
  background-color: #CCCCCC;
  opacity: 0.7;
  position: relative;
  transition: opacity 200ms ease-in-out;
  cursor: pointer;
  
  &:hover,
  &:active {
    background-color: #979797;
    opacity: 1;
  }
`;

export const ScrollThumbY = styled.div`
  ${thumbStd};
  width: ${({ scrollbarSize }) => scrollbarSize}px;
  right: ${({ scrollbarSize }) => scrollbarSize + 2}px;
`;

export const ScrollThumbX = styled.div`
  ${thumbStd};
  height: ${({ scrollbarSize }) => scrollbarSize}px;
  bottom: ${({ scrollbarSize }) => scrollbarSize + 2}px;
`;
