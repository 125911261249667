import React, { ReactNode } from 'react';

import { BtnWrap } from './styled';

interface Props {
  children: ReactNode | ReactNode[];
}

export function PopupBtnContainerModuleComponent({
  children,
}: Props) {
  return (
    <BtnWrap>
      {children}
    </BtnWrap>
  );
}

PopupBtnContainerModuleComponent.displayName = 'PopupBtnContainer';
