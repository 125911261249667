import React from 'react';

import useCode from './useCode';

import { Group, NumberInput, LineBtn, SolidBtn } from '~/atoms2';
import { PopupContent, PopupBtnContainer } from '~/components/modal2/modules';

import {
  DescList,
  CodeContainer, CodeContent,
} from './styled';

interface Props {
  callback: PopupSafeAuth.TSafeAuthCallback;
}

function CodeComponent({
  callback,
}: Props) {
  const {
    isSending,
    auth, remainingTime, changeCode,
    clickPrevStep, retry, submit,
    isDone,
  } = useCode({ callback });

  return (
    <>
      <PopupContent>
        <DescList>
          <li>
            <div>
              <p>입력하신 휴대폰정보가 일치하지 않을 경우, 인증문자가 발송되지 않습니다.</p>
            </div>
          </li>
          <li>
            <div>
              <p>3분 이내에 인증문자가 수신되지 않을 경우, 정확한 정보를 입력 후 재시도 해주시기 바랍니다.</p>
            </div>
          </li>
        </DescList>

        <CodeContainer>
          <Group
            title="인증번호"
            msg={auth.errorCode}
            isError={!!auth.errorCode}
          >
            <CodeContent>
              <NumberInput
                contentWidth="100%"
                isError={!!auth.errorCode}
                unit={remainingTime}
                max={6}
                value={auth.code}
                onChange={changeCode}
                placeholder="6자리 입력"
                disabled={isSending}
              />

              <LineBtn
                btnColor="gray"
                btnSize="lg"
                onClick={retry}
              >
                재발송
              </LineBtn>
            </CodeContent>
          </Group>
        </CodeContainer>
      </PopupContent>

      <PopupBtnContainer>
        <LineBtn
          contentWidth="120px"
          btnColor="gray"
          btnSize="xl"
          onClick={clickPrevStep}
          disabled={isSending}
        >
          뒤로
        </LineBtn>

        <SolidBtn
          contentWidth="120px"
          btnColor="blue"
          btnSize="xl"
          onClick={submit}
          disabled={!isDone || isSending}
        >
          본인인증 완료
        </SolidBtn>
      </PopupBtnContainer>
    </>
  );
}

export default CodeComponent;
