import React, { forwardRef, useMemo, ChangeEvent } from 'react';

import type { TextareaProps } from './types';

import {
  TextareaWrap,
  Content, LeftIconBtn, LeftIconWrap, TextArea,
  BottomContent, HelperText, Unit,
} from './styled';

export const TextareaUIComponent = forwardRef<HTMLTextAreaElement, TextareaProps>(({
  contentWidth = '100%',
  contentHeight = '78px',
  leftIcon,
  onLeftIconClick,
  min,
  max,
  hideMax = false,
  isCheckMax = true,
  helperText = '',
  maxLine,
  unit,
  isError = false,
  value ,
  onChange,
  onChangeNative,
  disabled = false,
  style,
  ...props
}, ref) => {
  const cnt = useMemo(() => {
    if (value !== undefined && max) {
      return typeof value === 'number' ? value.toString().length : value.length;
    }
    return 0;
  }, [max, value]);

  function change(e: ChangeEvent<HTMLTextAreaElement>) {
    const { name, value: tValue } = e.currentTarget;
    const targetValue = tValue;

    if (value !== undefined && isCheckMax && max && targetValue.length > max) return;

    const lineCnt = targetValue.split('\n').length;
    if (maxLine !== undefined && lineCnt > maxLine) return;

    onChangeNative?.(e);
    onChange?.({ name, value: tValue });
  }

  return (
    <TextareaWrap $contentWidth={contentWidth}>
      <Content
        $contentHeight={contentHeight}
        $isDisabled={disabled}
        style={style}
      >
        {leftIcon && (
          onLeftIconClick ? (
            <LeftIconBtn
              onClick={onLeftIconClick}
              disabled={disabled}
            >
              {leftIcon}
            </LeftIconBtn>
          ) : (
            <LeftIconWrap>
              {leftIcon}
            </LeftIconWrap>
          )
        )}

        <TextArea
          ref={ref}
          $isError={isError}
          $hasIcon={!!leftIcon}
          maxLength={isCheckMax ? max : undefined}
          value={value}
          onChange={change}
          disabled={disabled}
          autoComplete="off"
          {...props}
        />
      </Content>

      {(helperText || (max && !hideMax && !disabled) || unit) && (
        <BottomContent>
          {helperText && <HelperText $isError={isError}>{helperText}</HelperText>}

          {max && !hideMax && !disabled ? (
            <Unit $isActive={cnt > 0} $isError={cnt > max || (min !== undefined && min > cnt)}>
              {cnt}/{max}
            </Unit>
          ) : unit ? (
            <Unit $isActive>{unit}</Unit>
          ) : null}
        </BottomContent>
      )}
    </TextareaWrap>
  );
});

TextareaUIComponent.displayName = 'Textarea';
