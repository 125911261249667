import styled from 'styled-components';

export const Content = styled.div`
  display: grid;
  grid-template-columns: 100px minmax(0, 1fr);
  align-items: center;

  > p {
    color: ${({ theme }) => theme.color.gray[900]};
    ${({ theme }) => theme.font.body3};
  }
  
  & + & {
    margin-top: 8px;
  }
`;

export const Desc = styled.p`
  margin-top: 24px;
  color: ${({ theme }) => theme.color.blue[500]};
  ${({ theme }) => theme.font.body3};
`;
