import React from 'react';

import Empty from './components/empty';
import Recent from './components/recent';
import RoomSeq from './components/room-seq';
import RegionSubwayUniv from './components/region-subway-univ';
import Complex from './components/complex';
import SaleInLots from './components/sale-in-lots';

import { ListContainer, EasyContainer } from './styled';

interface Props {
  search: Search.SearchState;
  keywords: string[];
  searchedKeyword: string;
  clickClose(): void;
}

function SearchResultComponent({
  search,
  keywords,
  searchedKeyword,
  clickClose,
}: Props) {
  const hasNormalResult = (search.region.length + search.subway.length + search.univ.length) > 0;
  const hasComplexResult = search.complex.length > 0;
  const hasSaleInLotsResult = search.saleInLots.length > 0;

  return (
    <>
      {searchedKeyword !== '' ? (
        search.room !== null ? (
          <RoomSeq
            roomSeq={searchedKeyword}
            room={search.room}
            clickClose={clickClose}
          />
        ) : (hasNormalResult || hasComplexResult || hasSaleInLotsResult) ? (
          <ListContainer>
            <RegionSubwayUniv
              keywords={keywords}
              region={search.region}
              subway={search.subway}
              univ={search.univ}
              clickClose={clickClose}
            />

            <Complex
              keywords={keywords}
              complex={search.complex}
              clickClose={clickClose}
            />

            <SaleInLots
              keywords={keywords}
              saleInLots={search.saleInLots}
              clickClose={clickClose}
            />
          </ListContainer>
        ) : (
          <Empty />
        )
      ) : (
        <EasyContainer>
          <Recent clickClose={clickClose} />
        </EasyContainer>
      )}
    </>
  );
}

export default SearchResultComponent;
