import { phoneFormat } from '../format';
import isEmpty from './is-empty';

function checkCellPhone(num: string) {
  if (isEmpty(num)) return '휴대폰 번호를 입력해주세요.';

  const t = phoneFormat(num);

  if (!/^(01[0|1|6|7|8|9])[- ]?(\d{3,4})[- ]?(\d{4})$/g.test(t)) {
    return '휴대폰 번호 형식을 확인해주세요.';
  }

  return false;
}

export default checkCellPhone;
