import styled from 'styled-components';

export const Desc = styled.ul`
  color: ${({ theme }) => theme.color.gray[700]};
  ${({ theme }) => theme.font.body3};
  white-space: pre-wrap;
`;

export const CodeContainer = styled.div`
  margin-top: 24px;
  
  > p {
    color: ${({ theme }) => theme.color.blue[500]};
    ${({ theme }) => theme.font.body3};
    font-weight: 700;
    text-align: center;
  }
`;
