import { getMapCategory } from './get-map-category';

interface Props {
  center: Map.Location;
  roomType: 0 | 1 | 2 | 3 | 4 | 'ONE_ROOM' | 'TWO_ROOM' | 'THREE_ROOM' | 'OFFICETEL' | 'APT';
  sellingType: 0 | 1 | 2 | 'MONTHLY_RENT' | 'LEASE' | 'SELL';
  searchType: 'region';
  searchId: number | string;
}

export function getSimilarLink({
  center,
  roomType,
  sellingType,
  searchType,
  searchId,
}: Props) {
  const mapCategory = getMapCategory({
    roomType,
    sellingType,
  });

  const url = new URL(`${window.location.origin}/map/${mapCategory}`);

  url.searchParams.set('m_lat', center.lat.toString());
  url.searchParams.set('m_lng', center.lng.toString());

  if (roomType !== 'APT' &&  roomType !== 4) {
    url.searchParams.set('m_zoom', '15');
    url.searchParams.set('search_type', searchType);
    url.searchParams.set('search_id', searchId.toString());

  } else {
    url.searchParams.set('m_zoom', '16');
  }

  return `${url.pathname}${url.search}`;
}
