import styled from 'styled-components';

export const Desc = styled.div`
  margin-bottom: 32px;
  color: ${({ theme }) => theme.color.gray[700]};
  ${({ theme }) => theme.font.body3};
  white-space: pre-wrap;
`;

export const Dash = styled.p`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  
  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 1px;
    background-color: ${({ theme }) => theme.color.gray[400]};
  }
`;

export const SecretText = styled.p`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 8px;
  color: ${({ theme }) => theme.color.gray[900]};
  ${({ theme }) => theme.font.body3};
`;

export const PhoneContent = styled.div`
  display: grid;
  grid-template-columns: 120px minmax(0, 1fr);
  grid-column-gap: 16px;
`;
