import React from 'react';

import useCheckPhone from './useCheckPhone';

import { phoneFormat } from '~/utils/format';

import { LineBtn, SolidBtn } from '~/atoms2';
import { Popup } from '~/components/modal2';
import SafeAuthPopup from 'web~/components/popup/safe-auth';

import {
  Header,
  Content, DescTitle, Desc, PhoneInfo,
  BtnWrap,
} from './styled';

interface Props {
  callback?: () => void;
}

function CheckPhoneThreeMonthPopup({
  callback,
}: Props) {
  const {
    isSending,
    isOpenSafeAuth,
    phone,
    keepPhoneNumber,
    openSafeAuth,
    closeSafeAuth,
    editUserInfo,
  } = useCheckPhone({
    callback,
  });

  return (
    <Popup
      popupWidth={520}
      closePopup={() => {}}
      escToClose={false}
    >
      <Header>
        <h1>인증 후, 3개월이 지났어요!</h1>
      </Header>

      <Content>
        <DescTitle>아래 휴대폰 정보가 여전히 본인 번호가 맞으신가요?</DescTitle>
        <Desc>변경되었다면 추가 인증을 다시 진행해주세요.</Desc>
        <Desc>(번호를 갱신하지 않을 경우, 중개사로부터 연락을 받지 못할 수 있습니다.)</Desc>

        <PhoneInfo>
          <p>
            휴대폰 번호
            <span>{phoneFormat(phone)}</span>
          </p>
        </PhoneInfo>

        <BtnWrap>
          <LineBtn
            contentWidth="120px"
            btnColor="gray"
            btnSize="xl"
            onClick={keepPhoneNumber}
            disabled={isSending}
          >
            네, 맞아요
          </LineBtn>

          <SolidBtn
            btnColor="blue"
            btnSize="xl"
            onClick={openSafeAuth}
            disabled={isSending}
          >
            아니요, 바꿀게요
          </SolidBtn>
        </BtnWrap>
      </Content>

      {isOpenSafeAuth && (
        <SafeAuthPopup
          closePopup={closeSafeAuth}
          callback={editUserInfo}
        />
      )}
    </Popup>
  );
}

export default CheckPhoneThreeMonthPopup;
