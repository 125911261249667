import { useRef, useState, useEffect, useMemo } from 'react';
import { produce } from 'immer';
import { useAtom } from 'jotai';
import Cookie from 'js-cookie';

import type { S3ChangeEvent } from '~/atoms2';

import { safeAuthAtom } from '../../store';

import useApiCall from '~/hooks/useApiCall';
import { printError } from '~/utils/fetch';
import CountDown from '~/utils/count-down';
import { toast } from '~/components/toast';

interface Props {
  callback: PopupSafeAuth.TSafeAuthCallback;
}

function useCode({
  callback,
}: Props) {
  const _isMount = useRef(false);
  const _countDown = useRef<CountDown>();

  const [isSending, setSending] = useState(false);
  const [auth, setAuth] = useState({
    code: '',
    errorCode: '',
  });
  const [remainingTime, setRemainingTime] = useState('');

  const [state, setState] = useAtom(safeAuthAtom);

  const { get, post } = useApiCall();

  useEffect(() => {
    _isMount.current = true;
    _countDown.current = new CountDown(180);
    startCountDown();

    return () => {
      _isMount.current = false;
      _countDown.current?.stop();
    };
  }, []);

  function startCountDown() {
    _countDown.current?.start(({ minutes, seconds }, isEnd) => {
      if (isEnd) {
        clickPrevStep();
        toast.error('인증번호 입력 시간이 초과되었습니다. 재요청해주세요.');

      } else {
        setRemainingTime(`${_countDown.current?.formatZero(minutes)}:${_countDown.current?.formatZero(seconds)}`);
      }
    });
  }

  function changeCode({ value }: S3ChangeEvent) {
    setAuth(produce(draft => {
      draft.code = value;
      draft.errorCode = '';

      if (value.length < 6) {
        draft.errorCode = '인증번호 6자리를 입력해주세요.';
      }
    }));
  }

  /**
   * 뒤로
   */
  function clickPrevStep() {
    setState(draft => {
      draft.step = 'FORM';
      draft.requestSeq = '';
      draft.termsList = draft.termsList.map(item => ({
        ...item,
        checked: false,
      }));
    });
  }

  /**
   * 재시도
   */
  async function retry() {
    try {
      const res = await post(`/api/3/safe-auth/check/step1`, {
        name: state.name,
        jumin: `${state.birth}${state.genderNum}`,
        mobile_co: state.mobileCo,
        mobile_no: state.phone.replace(/-/g, ''),
      });

      if (!_isMount.current) return;

      if (res?.result) {
        setState(draft => {
          draft.step = 'CODE';
          draft.requestSeq = res.requestSeq;
        });

        startCountDown();

      } else {
        toast.error(res?.message ?? '서버에러가 발생했습니다.');
      }

    } catch (err) {
      printError(err);
    }
  }

  /**
   * 본인인증 완료
   */
  async function submit() {
    if (isSending) return;
    if (auth.code === '') return toast.error('');

    setSending(true);

    const termsHistorySeqList = state.termsList.map(item => item.termsHistorySeq).filter(Boolean);

    try {
      const step2 = await post('/api/3/safe-auth/check/step2', {
        request_seq: state.requestSeq,
        auth_no: auth.code,
        safe_auth_terms_history_seqs: JSON.stringify(termsHistorySeqList),
      });

      if (!_isMount.current) return;

      if (step2?.result) {
        _countDown.current?.stop();
        Cookie.set('safeAuthConfirm', step2?.safeAuthConfirm ?? '');

        const step3 = await get(`/api/3/safe-auth/verify/step3`);

        if (!_isMount.current) return;

        if (step3?.result) {
          callback?.({
            name: state.name,
            phone: state.phone.replace(/-/g, ''),
          });

        } else {
          setState(draft => {
            draft.step = 'ALREADY_IN_USE';
            draft.certError = {
              email: step3?.email ?? '',
              message: step3?.message ?? '',
            };
          });
        }

      } else {
        toast.error(step2?.message ?? '서버에러가 발생했습니다.');
        setSending(false);
      }

    } catch (err) {
      printError(err);
      if (!_isMount.current) return;
      setSending(false);
    }
  }

  const isDone = useMemo(() => {
    if (auth.code === '') return false;
    return auth.code.length === 6;

  }, [auth]);

  return {
    isSending,
    auth, remainingTime, changeCode,
    isDone,
    clickPrevStep, retry, submit,
  };
}

export default useCode;
