import Cookies from 'js-cookie';
import CreateFetch from './create';

export { printError } from './print-error';

export function checkToken() {
  const headers: Record<string, string> = {};

  const safeAuthConfirm = Cookies.get('safeAuthConfirm');

  if (safeAuthConfirm) {
    headers['Safe-Auth-Confirm'] = safeAuthConfirm;
  }

  // 항상 적용되는 헤더값
  const API_VERSION = '5.0.0';
  const APP_VERSION = '1';
  let CALL_TYPE = 'web';

  if (window.location.pathname.indexOf('/mobile') > -1) {
    CALL_TYPE = 'mobileweb';
  }

  headers['D-Api-Version'] = API_VERSION;
  headers['D-App-Version'] = APP_VERSION;
  headers['D-Call-Type'] = CALL_TYPE;

  return headers;
}

const apiCall = new CreateFetch({
  responseEntryKeyName: 'result',
  requestHeaders: checkToken,
  paramsSerializer(params) {
    if (window.location.pathname.indexOf('/mobile') > -1) {
      const authKey = Cookies.get('authKey');
      if (authKey) {
        params.authKey = authKey;
      }
    }

    return params;
  },
  errorHandler(err) {
    switch (err.code) {
      case 301:
        err.msg = '로그인이 만료되었습니다.';
        break;

      case 302:
        err.msg = '로그인이 필요합니다.';
        break;

      default:
        if (!err.msg) err.msg = '서버에러가 발생하였습니다.';
        break;
    }

    return err;
  },
});


export default apiCall;
