import styled from 'styled-components';

export const CloseBtn = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
  border: 0;
  background-color: transparent;
  position: absolute;
  top: 16px;
  right: 16px;
`;
