import React, { ReactNode } from 'react';

import { ReactComponent as CloseIcon } from './images/ic_24_close_big_gray900.svg';
import { Header } from './styled';

interface Props {
  title: ReactNode;
  closePopup: () => void;
  children?: ReactNode;
}

export function PopupHeaderModuleComponent({
  title,
  closePopup,
  children,
}: Props) {
  return (
    <Header>
      <div>
        {typeof title === 'string' ? (
          <h1>{title}</h1>
        ) : title}

        {children}
      </div>

      <button onClick={closePopup}>
        <CloseIcon />
      </button>
    </Header>
  );
}

PopupHeaderModuleComponent.displayName = 'PopupHeaderModule';
