import React from 'react';

import { ReactComponent as CloseIcon } from './images/ic_24_close_big_gray900.svg';
import { CloseBtn } from './styled';

interface Props {
  closePopup: () => void;
}

export function AlertCloseBtnModuleComponent({
  closePopup,
}: Props) {
  return (
    <CloseBtn onClick={closePopup}>
      <CloseIcon />
    </CloseBtn>
  );
}

AlertCloseBtnModuleComponent.displayName = 'AlertCloseBtn';
